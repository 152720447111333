import React from "react"

import AdminDashboardHeaderSection from "../../sections/admin_profile_sections/AdminDashboardHeaderSection"

import { useCurrentUser } from "../../components/CurrentUserContext"

import AdminSettingsSection from "../../sections/admin_profile_sections/AdminSettingsSection"

import AccessDeniedMessage from "../../components/AccessDeniedMessage"

import Layout from "../../components/Layout"

const AdminSettings = () => {
  const { currentUser, userLoading } = useCurrentUser()

  return (
    <Layout isSecondLayout={true}>
      {currentUser && currentUser.accountType === "school" ? (
        <div className="global-wrapper user-saved school-admin-global-wrapper">
          <AdminDashboardHeaderSection
            state3="active"
            choice_status3="chosen"
          />

          <div className="global-wrapper admin-profile published-info">
            <div className="school-admin-info-wrapper">
              <div className="sections-list section-list-width container">
                <div className="left-title school-info-title-info"></div>
                <AdminSettingsSection />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AccessDeniedMessage
          currentUser={currentUser}
          userLoading={userLoading}
        />
      )}
    </Layout>
  )
}

export default AdminSettings
