import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentUser } from "../../components/CurrentUserContext"
import axios from "../../http/axios"

const AdminSettingsSection = () => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const initialState = {
    oldPassword: "",
    newPassword: "",
    verifyNewPassword: "",
  }
  const [state, setState] = useState(initialState)
  const [err, setErr] = useState(null)

  const handleFormSubmit = e => {
    e.preventDefault()
    setErr(null)
    axios
      .post(`/accounts/${currentUser.id}/change-password`, state)
      .then(() => {
        setState(initialState)
      })
      .catch(e => {
        setErr(e?.response?.data?.message)
      })
  }

  const handleChange = event => {
    setState(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }))
  }

  const isDisabled = () => {
    return !(
      !!state.oldPassword.length &&
      !!state.newPassword.length &&
      state.verifyNewPassword === state.newPassword
    )
  }

  return (
    <div className="info-overview-wrapper f-js contacts-overview">
      <div className="info-overview-content-wrapper">
        <form onSubmit={handleFormSubmit}>
          <div className="school-info-title right-title">
            {t("schoolAdmin.changePassword")}
          </div>
          <div
            className="school-data-section students-number-section school-data-input-field"
            style={{ maxWidth: "320px" }}
          >
            <div className="input-group flex-column">
              <input
                onChange={handleChange}
                value={state.oldPassword}
                type="password"
                name="oldPassword"
                placeholder={t("schoolAdmin.settings.oldPassword")}
              />
            </div>

            <div className="input-group flex-column">
              <input
                onChange={handleChange}
                value={state.newPassword}
                type="password"
                name="newPassword"
                placeholder={t("schoolAdmin.settings.newPassword")}
              />
            </div>

            <div className="input-group flex-column">
              <input
                onChange={handleChange}
                value={state.verifyNewPassword}
                type="password"
                name="verifyNewPassword"
                placeholder={t("schoolAdmin.settings.verifyNewPassword")}
              />
              {state.verifyNewPassword.length > 0 &&
                state.verifyNewPassword !== state.newPassword && (
                  <span className={`error`}>
                    {t("schoolAdmin.settings.passwordsNotMatch")}
                  </span>
                )}
              {err && <span className={`error`}>{err}</span>}
            </div>

            <button
              disabled={isDisabled()}
              className={
                "show-all-button save-info-btn students-info-btn school-admin-info-btn" +
                (isDisabled() ? " disabled-btn" : "")
              }
              type="submit"
            >
              <div>{t("main.userProfileMap.save")}</div>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AdminSettingsSection
